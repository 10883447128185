
  import { mapState } from 'vuex'
export default {
    props: {
        blok: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        ...mapState(['channel']),

        imagePosition() {
            return this.blok.image_position === 'right' ? 'image-right' : 'image-left';
        },

        mobile() {
            return (this.$mq == 'sm' || this.$mq == 'md')
        },
    },
    methods: {
        handleButtonClick() {
            if (this.blok.button[0].url.cached_url) {
                window.location.href = this.blok.button[0].url.cached_url;
            } else {
                console.log('No URL provided for button.');
            }
        }
    }
}
